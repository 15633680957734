import moment from 'moment';
import { nanoid } from '@reduxjs/toolkit';
import { Progress, Select, Tag } from 'antd';
import React from 'react';
import statuses from '../features/kanban/data/statuses';

const submittalStatus = ['Open', 'Closed'];

const { Option } = Select;

const todayNumber = Number(moment(new Date()).format('DDMMYYYY'));
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const imageUrlToBase64 = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const convertEditorFormat = (data, files) => {
  const obj =
    files && files.length > 0
      ? {}
      : {
          0: {
            type: 'IMAGE',
            mutability: 'IMMUTABLE',
            data: {
              src: ''
            }
          }
        };
  files &&
    files.length > 0 &&
    files.map((v, i) => {
      obj[i] = {
        type: 'IMAGE',
        mutability: 'IMMUTABLE',
        data: {
          src: v.download_url
        }
      };
    });
  return {
    entityMap: {
      ...obj
    },
    blocks: [
      {
        key: '9gm3s',
        text: data ? removeTags(data) : '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ]
  };
};

export const removeDuplicates = (arr, key, reverse = false) => {
  const finalArray = reverse ? [...arr].reverse() || [] : arr || [];
  return (
    Array(finalArray) &&
    finalArray.length > 0 &&
    finalArray.reduce((acc, current) => {
      const x = acc.find((item) => item[key] === current[key]);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    }, [])
  );
};
function removeTags(str) {
  if (str === null || str === '') return false;
  str = str.toString();
  return str?.replace(/(<([^>]+)>)/gi, '');
}
export const constructCreateRFI = (values, isDraft, files, htmlContent, edit) => {
  const customFields = customFieldsPayload(values.custom_field);

  const assignee_id =
    values?.assignees.length > 0
      ? values?.assignees.map((v) => {
          const isDestroy = values?.deletedUsers.includes(Number(v.user_id));
          const obj = {
            user_id: v.user_id,
            response_required: v.response_required,
            _destroy: isDestroy
          };
          return edit && v?.id ? { ...obj, id: v.id } : obj;
        })
      : [];

  // const allAssignees = removeDuplicates(assignee_id, 'user_id');
  const rawHtml = htmlContent.replace(/<img[^>]*>/g, '');
  const formData = new FormData();
  formData.append('rfi[created_by_id]', values.created_by_id);
  formData.append('rfi[distribution_ids]', JSON.stringify(values.distribution_list));
  formData.append('rfi[number]', values.number || '');
  formData.append('rfi[subject]', values.subject);
  formData.append('rfi[rfi_manager_id]', values.rfi_manager);
  formData.append('rfi[due_date]', moment(values.due_date).format('DD/MM/YYYY'));
  formData.append('rfi[rfi_assignees_attributes]', JSON.stringify(assignee_id));
  formData.append('rfi[received_from_id]', values.received_from);
  if (typeof values?.location?.value === 'number') {
    formData.append('rfi[location_id]', values?.location?.value ? values?.location?.value : '');
  } else {
    formData.append('rfi[location_id]', values?.location?.value ? values?.location?.value : '');
  }
  // formData.append('rfi[location_id]', values.location || '');
  formData.append('rfi[drawing_id]', values.drawing_no || '');
  formData.append('rfi[project_stage_id]', values?.project_stage || '');
  formData.append('rfi[responsible_contractor_id]', values.responsible_contractor);
  formData.append('rfi[rfi_custom_field_responses_attributes]', JSON.stringify(customFields) || []);
  if (values.private) {
    formData.append('rfi[private]', values.private);
  }
  formData.append(
    'rfi[schedule_impact]',
    JSON.stringify({
      status: values.schedule_impact,
      value: values.schedule_impact === 'yes_known' ? values.days : ''
    })
  );
  formData.append(
    'rfi[cost_impact]',
    JSON.stringify({
      status: 'na', // values?.cost_impact
      value: values?.cost_impact === 'yes_known' ? values.cost : 0
    })
  );
  formData.append('rfi[rfi_question_attributes][body]', rawHtml);
  files &&
    files.length > 0 &&
    files.map((v, i) => {
      formData.append(`rfi_response[rfi_question_attributes][files][${i}]`, v.file);
    });
  if (isDraft) {
    formData.append('rfi[draft]', true);
  }

  return formData;
};

export const setFormFieldsForRFI = (rfiData, location = []) => {
  return {
    number: rfiData.number,
    subject: rfiData.subject,
    due_date: moment(rfiData.due_date && moment(new Date(rfiData?.due_date)).toISOString(true)),
    responsible_contractor: rfiData.responsible_contractor_id,
    received_from: rfiData.received_from_id,
    rfi_manager: rfiData.rfi_manager_id,
    // assignees: rfiData.assignees.map((x) => x.user_id),
    drawing_no: rfiData.drawing_number,
    location: location.length > 0 ? [{ label: location[0].key, value: location[0].value }] : [],
    spec_section: rfiData.spec_section,
    schedule_impact: rfiData?.schedule_impact?.status ? rfiData.schedule_impact.status : 0,
    days: rfiData?.schedule_impact?.value ? rfiData?.schedule_impact?.value : 0,
    // cost: rfiData?.cost_impact?.value,
    project_stage: rfiData.project_stage_id,
    // cost_impact: rfiData.cost_impact.status,
    sub_job: rfiData.sub_job,
    private: rfiData.private,
    question_validator: rfiData.question.body
  };
};

export const constructUpdateSettingsPayload = (data) => {
  return {
    sequence_number: data.sequence_number,
    sequence_number_type: data.sequence_number_type,
    sequence_number_prefix: data.sequence_number_prefix,
    enable_overdue_notification: data.enable_overdue_notification,
    is_private: data.is_private,
    default_due_time: data.default_due_time,
    default_manager_id: data.default_manager_id,
    default_distribution_ids: [...new Set(data.default_distribution_ids)],
    enable_distribution_list: data.enable_distribution_list
  };
};

export const constructViewSubmittalResponse = (content, files, info) => {
  const rawHtml = content.replace(/<img[^>]*>/g, '');
  const formData = new FormData();
  formData.append('submittal_response[notes]', rawHtml);
  formData.append(
    'submittal_response[status]',
    info?.status !== 'pending' ? info?.status : 'submitted'
  );
  files &&
    files.length > 0 &&
    files
      .filter((e) => e.originFileObj)
      .map((v, i) => {
        if (v.originFileObj) formData.append(`submittal_response[files][${i}]`, v.originFileObj);
      });
  return formData;
};

export const customFieldsPayload = (data = []) => {
  const customFields = [];
  data &&
    data.map((v) => {
      const obj = { ...v };
      const ids = obj?.id.toString();
      if (ids && ids.includes(todayNumber)) {
        delete obj.id;
      }
      customFields.push(obj);
    });
  return customFields;
};

export const constructCreateSubmittalPayload = (data) => {
  // console.log(data)
  const customFields = customFieldsPayload(data.custom_field);
  const submittalResponses = data.workFlow.map((value) => {
    const id = value?.id;
    const obj = {
      user_id: value.user_id,
      role: value.role,
      due_date: moment(value.due_date).format('DD/MM/YYYY'),
      _destroy: !!value?._destroy
    };
    return id && id.toString().length < 10 ? { ...obj, id: value.id } : obj;
  });
  // const distribution_users =
  //   data?.distributionUsers && data.distributionUsers.length > 0
  //     ? data.distributionUsers.map((e) => e)
  //     : [];
  return {
    submittal: {
      subject: data.subject,
      description: data.description,
      number: data.number,
      submittal_type_id: data.submittal_type,
      responsible_contractor_id: data.responsible_contractor,
      submittal_manager_id: data.submittal_manager,
      response_required_by: data.response_date
        ? moment(new Date(data.response_date)).format('DD/MM/YYYY')
        : moment(new Date()).format('DD/MM/YYYY'),
      due_date: data.submittal_approval_due
        ? moment(new Date(data.submittal_approval_due)).format('DD/MM/YYYY')
        : moment(new Date()).format('DD/MM/YYYY'),
      distribution_user_ids: data.distributionUsers,
      required_on_site: data.on_site_date
        ? moment(new Date(data.on_site_date)).format('DD/MM/YYYY')
        : moment(new Date()).format('DD/MM/YYYY'),
      lead_time: data.lead_time_day,
      drawing_id: data.drawing_no ? data.drawing_no : null, // data.drawing_no
      location_id: data.location ? data.location : null, // number
      private: data.private,
      submittal_custom_field_responses_attributes: customFields,
      submittal_responses_attributes: submittalResponses
    }
  };
};

export const constructWorkflow = (name, data) => {
  const userArry = data.map((value) => {
    const id = value?.id;
    const obj = { user_id: value.user_id, role: value.role, _destroy: !!value?._destroy };
    return id && id.toString().length < 10 ? { ...obj, id: value.id } : obj;
  });
  return {
    submittal_workflow: {
      name: name,
      submittal_workflow_users_attributes: userArry
    }
  };
};

export const optionsHelper = (data) => {
  return data && data.length > 0
    ? data.map((v) => {
        return { label: v.key, value: v.value };
      })
    : [];
};

export const Capitalize = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const mergeArrayOfObjects = (original, newdata, selector = 'user_id') => {
  return original.concat(
    newdata.filter((bo) => original.every((ao) => ao[selector] !== bo[selector]))
  );

  // newdata.forEach((dat) => {
  //   const foundIndex = original.findIndex((ori) => ori[selector] === dat[selector]);
  //   if (foundIndex >= 0) original.splice(foundIndex, 1, dat);
  //   else original.push(dat);
  // });
  // return original;
};

export const constructCustomFields = (data = [], isEdit = false) => {
  return (
    data &&
    data.length > 0 &&
    data.map((c, index) => {
      return {
        id: Number(`${moment(new Date()).format('DDMMYYYY')}${index}`),
        input_name: c?.label ? c?.label : c.title,
        input_options: c.input_options,
        input_type: c.input_type,
        response_value: c?.response_value ? c?.response_value : '',
        _destroy: false
      };
    })
  );
};

export const generateCustomOptions = (options = []) => {
  return (
    options &&
    options.length > 0 &&
    options.map((e) => {
      return (
        <Option value={e.id} key={e.id} id={e.id} title={e.input_name}>
          <label className="option-label">{e.input_name}</label>
          <div className="custom-list-view">
            <Tag>{e.input_type}</Tag>
          </div>
        </Option>
      );
    })
  );
};

export const generateBoardData = (data) => {
  const columns = [];
  let cards = [];
  if (Object.keys(data).length > 0) {
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      const title = capitalizeFirstLetter(key?.split('_').join(' '));
      const colValues = generateColumns(title, value);
      columns.push(colValues);
      const returnValues =
        value.length > 0 &&
        value.map((e) => {
          return { ...e, status: title, id: e.id.toString() };
        });
      cards = [...cards, ...returnValues];
    });
  }
  const newValues = mapOrder(columns, statuses, 'id');
  return { columns: newValues, cards };
};

const generateColumns = (key, values) => {
  return {
    id: key,
    title: key,
    cardsIds: values.length > 0 ? values.map((e) => e.id.toString()) : []
  };
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    const A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
}

export const generateBoardList = (data, page) => {
  const columns = [];
  let cards = [];
  let state = {};
  if (Object.keys(data).length > 0) {
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      const title = capitalizeFirstLetter(key?.split('_').join(' '));
      const colValues = generateColumns(title, value.data);
      columns.push(colValues);
      const returnValues =
        value?.data?.length > 0 &&
        value?.data.map((e) => {
          return { ...e, status: title, id: e.id.toString() };
        });
      cards = [...cards, ...returnValues];
      state = { ...state, [key]: value.pagination };
    });
  }
  const status = page === 'punch_lists' ? statuses : submittalStatus;
  const newValues = mapOrder(columns, status, 'id');
  return { columns: newValues, cards, state };
};

export function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele !== value;
  });
}

export function addOrRemoveObjects(myArray = [], values, checked, id) {
  if (checked) {
    const { key, value } = values;
    return myArray.push(values);
  }
  return myArray.filter((obj) => obj.user_id !== id);
}

export const formatFile = {
  pdf: ''
};

// const convertJsonToFormData = (data, parentKey = null, formData = new FormData()) => {
//   if (Array.isArray(data)) {
//     data.forEach((item, index) => {
//       const newKey = `${parentKey}[${index}]`;
//       convertJsonToFormData(item, newKey, formData);
//     });
//   } else if (typeof data === 'object') {
//     for (const key in data) {
//       const value = data[key];
//       const newKey = parentKey ? `${parentKey}.${key}` : key;
//
//       if (Array.isArray(value) || typeof value === 'object') {
//         convertJsonToFormData(value, newKey, formData);
//       } else {
//         formData.append(newKey, value);
//       }
//     }
//   }
//
//   return formData;
// };
//
// // Example JSON
// const jsonData = [
//   {
//     folder_name: 'v1',
//     elements: [
//       {
//         file_name: 'file1',
//         folder_name: 'v1_1',
//         elements: [
//           {
//             file_name: 'file2'
//           }
//         ]
//       }
//     ]
//   }
// ];
//
// const formData = convertJsonToFormData(jsonData);

const convertJsonToFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      convertJsonToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export function jsonToFormData(data) {
  const formData = new FormData();
  convertJsonToFormData(formData, data);
  return formData;
}

// Now you can send the formData using fetch or XMLHttpRequest

export const constructDirectoryJson = (files) => {
  const tree = [];

  files.forEach((file) => {
    const pathParts = file.originFileObj.webkitRelativePath.split('/');
    let currentLevel = tree;

    pathParts.forEach((part, index) => {
      const existingFolder = currentLevel.find((item) => item.folder_name === part);

      if (existingFolder) {
        currentLevel = existingFolder.elements;
      } else {
        const newFolder = {
          folder_name: part,
          elements: []
        };

        currentLevel.push(newFolder);
        currentLevel = newFolder.elements;
      }

      if (index === pathParts.length - 1) {
        currentLevel.push({
          file_name: file.name
        });
      }
    });
  });

  return tree;
};

export const CapitalizeWord = (text) => {
  const words = text.split('_');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export function getRandomColor() {
  return (
    '#' +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
      .toUpperCase()
  );
}

export const fileStatus = (name, data) => {
  const percentage = data?.summary_data?.no_of_processed_files
    ? (data?.summary_data?.no_of_processed_files / data?.no_of_pages) * 100
    : 0;
  const obj = {
    in_queue: { name: 'In Queue', value: '', className: '', status: '' },
    in_progress: { name: 'In progress', value: 30, className: 'in-progress', status: '' },
    ready_for_review: {
      name: 'ready for review',
      value: 100,
      className: 'ready-for-review',
      status: ''
    },
    waiting: { name: 'waiting', value: '', className: '', status: '' },
    reviewed: { name: 'upload completed', value: 100, className: '', status: '' },
    failed: { name: 'upload failed', value: 100, className: 'upload-failed', status: '' }
  };
  return (
    <>
      <Progress
        percent={percentage || 0}
        status={obj[name]?.status}
        showInfo={false}
        className={obj[name].className}
      />
      <span>
        {obj[name].name}
        {data.summary_data?.no_of_processed_files && data?.no_of_pages ? (
          <> | {data.summary_data.no_of_processed_files + ' / ' + data.no_of_pages}</>
        ) : (
          ''
        )}
      </span>
    </>
  );
};
