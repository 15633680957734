import React from 'react';
import ProjectLoopLogo from "../../../../images/SL_logo1.png";
import "./styles.scss";

export default function ParallaxSection() {
    return (
        <div className="Industry-export-section">
            <div className="export-section-content">
                <div className="home-container sl-parallax-container">
                    <div className="export-details">
                        <img src={ProjectLoopLogo} className="logo" />
                        <h2>Designed & Developed By <br />Safety Industry Experts</h2>
                        <p>
                            Crafted by a dedicated team of safety experts boasting more than 70 years of combined safety management expertise and two decades of experience in IT development, we discerned a vital requirement for a safety software created by safety managers, for safety managers. Your request has been heard, and we've turned it into a reality.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
