/* eslint-disable react/no-unused-state */
import React, { Suspense } from 'react';
import { Spin, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Spinner = () => {
  return (
    <Row style={{ width: '100%', height: '100vh' }} justify="center" align="middle">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24
            }}
            spin
          />
        }
      />
    </Row>
  );
};

class SuspenseWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log('error is => ', error);
    console.log('errorInfo.commponentStack => ', errorInfo && errorInfo.componentStack);
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    return this.state.errorInfo ? null : (
      <Suspense fallback={<Spinner />}>{this.props.children}</Suspense>
    );
  }
}
export default SuspenseWrapper;
