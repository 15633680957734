import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../actions';
import punchService from '../services/PunchList';
import { API_SERVICE } from '../services/CommonApi';

const initialState = {
  punchList: {
    data: []
  },
  punchListFilters: [],
  punchListStatus: false,
  deletePunchStatus: false,
  punchListError: '',
  closedPunchlist: ''
};
export const getViewPunchList = createAsyncThunk(
  ACTIONS.GET_PUNCH_LIST_ACTION,
  async (arg, thunkAPI) => {
    try {
      const payload = arg.filters ? { page: arg.page, per_page: 5, filters: arg.searchData } : {};
      return API_SERVICE.get(`${punchService.getPunchList(arg.projectId)}`, payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const changePunchListStatus = createAsyncThunk(
  ACTIONS.CHANGE_PUNCH_LIST_STATUS,
  async (arg, thunkAPI) => {
    try {
      const response = await API_SERVICE.patch(
        `${punchService.changePunchListStatus(arg.projectId, arg.punchListId)}`,
        arg.payload
      );
      thunkAPI.dispatch(getViewPunchList());
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getViewPunchListFilters = createAsyncThunk(
  ACTIONS.GET_PUNCH_LIST_FILTERS_ACTION,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${punchService.punchListFilters(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deletePunchList = createAsyncThunk(ACTIONS.DELETE_PUNCHLIST, async (arg, thunkAPI) => {
  try {
    thunkAPI.dispatch(getViewPunchList(arg.projectId));
    return await API_SERVICE.delete(
      `${punchService.viewPunchList(arg.projectId, arg.punchListId)}`
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const closedPunchList = createAsyncThunk(ACTIONS.MARK_AS_CLOSED, async (arg, thunkAPI) => {
  try {
    const result = await API_SERVICE.patch(
      `${punchService.markAsClosed(arg.projectId, arg.punchListId)}`
    );
    thunkAPI.dispatch(getViewPunchList(arg.projectId));
    return result;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const PunchListSlice = createSlice({
  name: 'PUNCH',
  initialState,
  reducers: {
    resetPunchListStatus: (state, action) => {
      state.punchListStatus = false;
      state.closedPunchlist = '';
      state.punchListError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewPunchList.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.punchList = {
            data: payload.data.data,
            ...payload.headers
          };
        }
      })
      .addCase(getViewPunchListFilters.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.punchListFilters = payload.data;
        }
      })
      .addCase(changePunchListStatus.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.punchListStatus = true;
        }
      })
      .addCase(closedPunchList.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.closedPunchlist = 'PunchList closed successfully';
        } else {
          state.punchListError = payload.response.data.error;
        }
      })
      .addCase(deletePunchList.fulfilled, (state, { meta, payload }) => {
        if (payload.status === 200 || payload.status === 201) {
          state.closedPunchlist = 'PunchList deleted successfully';
        } else {
          state.punchListError = payload.response.data.error;
        }
      });
  }
});

export const { resetPunchListStatus } = PunchListSlice.actions;
export default PunchListSlice.reducer;
