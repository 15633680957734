import React, { lazy } from 'react';
import Layout from '../components/Layout';
import SuspenseWrapper from '../components/SuspenseWrapper';

const ProjectList = lazy(() => import('../pages/PDF/List'));
const PDFView = lazy(() => import('../pages/PDF/View'));
const Drawing = lazy(() => import('../pages/Drawing'));
const ViewProjects = lazy(() => import('../pages/ProjectNew/view'));
const Company = lazy(() => import('../pages/Company'));
const RFI = lazy(() => import('../pages/RFI'));
const RFIView = lazy(() => import('../pages/RFI/Components/View'));
// const Submittal = lazy(() => import('../pages/Submittal'));
const Submittal = lazy(() => import('../pages/SubmittalsNew'));
const CreateRFI = lazy(() => import('../pages/RFI/Components/CreateRFI'));
const PdfInfo = lazy(() => import('../pages/PDF/Info'));
// const Settings = lazy(() => import('../pages/RFI/Components/Settings'));
// const SubmittalSetting = lazy(() => import('../pages/Submittal/components/Settings'));
const SubmittalsForm = lazy(() => import('../pages/SubmittalsNew/components/Form'));
const SubmittalView = lazy(() => import('../pages/SubmittalsNew/components/View'));
const PunchList = lazy(() => import('../pages/PunchList/List'));
const PunchListCreate = lazy(() => import('../pages/PunchList'));
const ViewPunchList = lazy(() => import('../pages/PunchList/view'));
const ProjectSettings = lazy(() => import('../pages/ProjectSettings'));
const DailyLogs = lazy(() => import('../pages/DailyLogsNew'));
// const KanbanBoard = lazy(() => import('../components/KanbanBoard'));
const Pipeline = lazy(() => import('../pages/Pipeline'));
const PipelineForm = lazy(() => import('../pages/Pipeline/components/Form'));
const PipelineView = lazy(() => import('../pages/Pipeline/components/View'));
const PipelineEdit = lazy(() => import('../pages/Pipeline/components/Edit'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ProjectEdit = lazy(() => import('../pages/ProjectNew/components/Edit'));
const ProjectForm = lazy(() => import('../pages/ProjectNew/components/Form'));
const DrawingArea = lazy(() => import('../pages/Drawing/drawingArea'));
const CompanySettings = lazy(() => import('../pages/Company/Settings'));
const SubmittalEdit = lazy(() => import('../pages/SubmittalsNew/components/Edit'));
const { ViewLocationContainer } = lazy(() => import('../pages/ProjectSettings/location'));
const Directory = lazy(() => import('../pages/Directory'));
const ProjectCompany = lazy(() => import('../pages/Directory/components/ProjectCompany'));
const ProjectCompanyEdit = lazy(() => import('../pages/Directory/components/ProjectCompanyEdit'));
const ProjectUserEdit = lazy(() => import('../pages/Directory/components/ProjectUserEdit'));
const ProjectUserView = lazy(() => import('../pages/Directory/components/UserViewWrapper'));
// const MembersWrapper = lazy(() => import('../pages/Directory/components/MembersWrapper'));
const CompanyDirectory = lazy(() => import('../pages/Company/Directory'));
const CompanyContainer = lazy(() => import('../pages/Company/Directory/components/Company'));
const UserView = lazy(() => import('../pages/Company/Directory/components/UserViewWrapper'));
const UserEdit = lazy(() => import('../pages/Company/Directory/components/UserEdit'));
const CompanyEdit = lazy(() => import('../pages/Company/Directory/components/CompanyEdit'));
const CurrentCompanyUserViewWrapper = lazy(() =>
  import('../pages/Company/Directory/components/CurrentCompanyUserViewWrapper')
);
const CurrentCompanyUserEdit = lazy(() =>
  import('../pages/Company/Directory/components/CurrentCompanyUserEdit')
);
const EntryLevel = lazy(() => import('../pages/ProjectDocument/EntryLevel'));
const SingUp = lazy(() => import('../pages/Login/SignUp'));
const DailyLogsWrapper = lazy(() => import('../pages/DailyLogsNew/components/LogsWrapper'));
const Survey = lazy(() => import('../pages/Survey'));
const DrawingList = lazy(() => import('../pages/DrawingNew/List'));
const SurveyCreatorWidget = lazy(() => import('../pages/Survey/components/SurveyCreatorWidget'));
const SurveyEditor = lazy(() => import('../pages/Survey/components/SurveyEditer'));
const Roles = lazy(() => import('../pages/Roles'));
const RoleView = lazy(() => import('../pages/Roles/components/View'));
const DrawingReviewList = lazy(() => import('../pages/DrawingNew/DrawingReviewList'));
const Schedule = lazy(() => import('../pages/Schedule'));
const MasterView = lazy(() => import('../pages/Schedule/componants/MasterView'));
const LookaheadView = lazy(() => import('../pages/Schedule/componants/LookaheadView'));
const DrawingPreview = lazy(() => import('../pages/DrawingNew/View'));
const ActionItems = lazy(() => import('../pages/ActionItem'));
const ActionView = lazy(() => import('../pages/ActionItem/components/View'));
const UserProfile = lazy(() => import('../pages/UserProfile'));

const protectedRoutes = [
  {
    path: '/dashboard',
    element: (
      <Layout onlyUserInfo>
        <Dashboard />
      </Layout>
    )
  },
  { path: '/company', element: <Company /> },
  {
    path: '/company/settings',
    element: (
      <Layout onlyUserInfo>
        <CompanySettings />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/user/:userId',
    element: (
      <Layout onlyUserInfo>
        <CurrentCompanyUserViewWrapper />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/user/:userId/edit',
    element: (
      <Layout onlyUserInfo>
        <CurrentCompanyUserEdit />
      </Layout>
    )
  },
  {
    path: '/projects-list/:companyId',
    element: (
      <Layout>
        <ProjectList />
      </Layout>
    )
  },
  {
    path: '/projects/:projectId',
    element: (
      <Layout>
        <ViewProjects />
      </Layout>
    )
  },
  {
    path: '/projects/:projectId/settings',
    element: (
      <Layout>
        <ProjectSettings />
      </Layout>
    )
  },
  {
    path: '/projects-list/:companyId/create',
    element: (
      <Layout>
        <ProjectForm />
      </Layout>
    )
  },
  {
    path: '/projects-list/:projectId/edit',
    element: (
      <Layout>
        <ProjectEdit />
      </Layout>
    )
  },
  {
    path: '/drawing-areas/:projectId',
    element: (
      <Layout>
        <DrawingArea />
      </Layout>
    )
  },
  {
    path: '/drawings-list/:projectId',
    element: (
      <Layout>
        <Drawing />
      </Layout>
    )
  },
  {
    path: '/drawing-review/:projectId/:drawingId',
    element: (
      <Layout>
        <DrawingReviewList />
      </Layout>
    )
  },
  {
    path: '/drawings/:revisionId/:projectId',
    element: <PDFView />
  },
  {
    path: '/drawing-info/:id',
    element: (
      <Layout>
        <PdfInfo />
      </Layout>
    )
  },
  {
    path: '/drawings/:id',
    element: <DrawingPreview />
  },
  {
    path: '/rfi/:projectId',
    element: (
      <Layout>
        <RFI />
      </Layout>
    )
  },
  {
    path: '/rfi/:projectId/view/:rfiId',
    element: (
      <Layout>
        <RFIView />
      </Layout>
    )
  },
  {
    path: '/rfi/:projectId/edit/:rfiId',
    element: (
      <Layout>
        <CreateRFI />
      </Layout>
    )
  },
  {
    path: '/rfi/:projectId/create',
    element: (
      <Layout>
        <CreateRFI />
      </Layout>
    )
  },
  {
    path: '/submittals/:projectId',
    element: (
      <Layout>
        <Submittal />
      </Layout>
    )
  },
  {
    path: '/submittals/:projectId/create',
    element: (
      <Layout>
        <SubmittalsForm />
      </Layout>
    )
  },
  {
    path: '/submittals/:projectId/edit/:submittalId',
    element: (
      <Layout>
        <SubmittalEdit />
      </Layout>
    )
  },
  {
    path: '/submittals/:projectId/view/:id',
    element: (
      <Layout>
        <SubmittalView />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId',
    element: (
      <Layout>
        <PunchList />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId/create',
    element: (
      <Layout>
        <PunchListCreate />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId/create/:subTaskId',
    element: (
      <Layout>
        <PunchListCreate />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId/view/:punchListId',
    element: (
      <Layout>
        <ViewPunchList />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId/create-sub-task/:punchListId',
    element: (
      <Layout>
        <ViewPunchList />
      </Layout>
    )
  },
  {
    path: '/punch-list/:projectId/edit/:punchListId',
    element: (
      <Layout>
        <PunchListCreate />
      </Layout>
    )
  },
  {
    path: '/daily-logs/:projectId',
    element: (
      <Layout>
        <DailyLogs />
      </Layout>
    )
  },
  {
    path: '/daily-logs/:projectId/:reportDate',
    element: (
      <Layout>
        <DailyLogsWrapper />
      </Layout>
    )
  },
  {
    path: '/pipeline',
    element: (
      <Layout onlyUserInfo>
        <Pipeline />
      </Layout>
    )
  },
  {
    path: '/actions/:projectId',
    element: (
      <Layout>
        <ActionItems />
      </Layout>
    )
  },
  {
    path: '/actions/:projectId/view/:actionId',
    element: (
      <Layout>
        <ActionView />
      </Layout>
    )
  },
  {
    path: '/schedule/:projectId',
    element: (
      <Layout>
        <Schedule />
      </Layout>
    )
  },
  {
    path: '/schedule/:projectId/master_schedule/:scheduleId',
    element: (
      <Layout>
        <MasterView />
      </Layout>
    )
  },
  {
    path: '/schedule/:projectId/lookahead/:scheduleId',
    element: (
      <Layout>
        <LookaheadView />
      </Layout>
    )
  },
  {
    path: '/survey/:inspectionId/edit',
    element: (
      <Layout onlyUserInfo isSurvey>
        <SurveyEditor />
      </Layout>
    )
  },
  {
    path: '/survey/:templateId',
    element: (
      <Layout onlyUserInfo isSurvey>
        <SurveyCreatorWidget />
      </Layout>
    )
  },
  {
    path: '/survey',
    element: (
      <Layout onlyUserInfo>
        <Survey />
      </Layout>
    )
  },
  {
    path: '/roles-and-permissions/:roleId',
    element: (
      <Layout onlyUserInfo>
        <RoleView />
      </Layout>
    )
  },
  {
    path: '/roles-and-permissions',
    element: (
      <Layout onlyUserInfo>
        <Roles />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/directory',
    element: (
      <Layout onlyUserInfo>
        <CompanyDirectory />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/company-directory/:directoryId',
    element: (
      <Layout onlyUserInfo>
        <CompanyContainer />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/company-directory/:directoryId/edit',
    element: (
      <Layout onlyUserInfo>
        <CompanyEdit />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/user-directory/:directoryId',
    element: (
      <Layout onlyUserInfo>
        <UserView />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/company-user-directory/:directoryId',
    element: (
      <Layout onlyUserInfo>
        <UserView noRedirect />
      </Layout>
    )
  },
  {
    path: '/company/:companyId/user-directory/:directoryId/edit',
    element: (
      <Layout onlyUserInfo>
        <UserEdit />
      </Layout>
    )
  },
  {
    path: '/pipeline/create',
    element: (
      <Layout onlyUserInfo>
        <PipelineForm />
      </Layout>
    )
  },
  {
    path: '/pipeline/:tradeId',
    element: (
      <Layout onlyUserInfo>
        <PipelineView />
      </Layout>
    )
  },
  {
    path: '/pipeline/trash/:tradeId',
    element: (
      <Layout onlyUserInfo>
        <PipelineView viewOnly />
      </Layout>
    )
  },
  {
    path: '/pipeline/:tradeId/edit',
    element: (
      <Layout onlyUserInfo>
        <PipelineEdit />
      </Layout>
    )
  },
  { path: '/project_locations/:locationId/qr_codes/:qrId', element: <ViewLocationContainer /> },
  {
    path: '/directory/:projectId',
    element: (
      <Layout>
        <Directory />
      </Layout>
    )
  },
  {
    path: '/directory/:projectId/project-company/:projectCompanyId',
    element: (
      <Layout>
        <ProjectCompany />
      </Layout>
    )
  },
  {
    path: '/directory/:projectId/project-company/:projectCompanyId/edit',
    element: (
      <Layout>
        <ProjectCompanyEdit />
      </Layout>
    )
  },
  {
    path: '/directory/:projectId/project-user/:projectUserId',
    element: (
      <Layout>
        <ProjectUserView />
      </Layout>
    )
  },
  // {
  //   path: '/directory/:projectId/members/:crewId',
  //   element: (
  //     <Layout>
  //       <MembersWrapper />
  //     </Layout>
  //   )
  // },

  {
    path: '/directory/:projectId/project-company-user/:projectUserId',
    element: (
      <Layout>
        <ProjectUserView noRedirect />
      </Layout>
    )
  },
  {
    path: '/directory/:projectId/project-user/:projectUserId/edit',
    element: (
      <Layout>
        <ProjectUserEdit />
      </Layout>
    )
  },
  {
    path: '/projects-document/:projectId',
    element: (
      <Layout>
        <EntryLevel />
      </Layout>
    )
  },
  {
    path: '/company-document/:companyId',
    element: (
      <Layout>
        <EntryLevel />
      </Layout>
    )
  },
  {
    path: '/drawing/:projectId',
    element: (
      <Layout>
        <DrawingList />
      </Layout>
    )
  },
  {
    path: '/user-profile',
    element: (
      <Layout>
        <UserProfile />
      </Layout>
    )
  },
  {
    path: '*',
    element: (
      <Layout onlyUserInfo>
        <Dashboard />
      </Layout>
    )
  },
  {
    path: '/companies/:companyId/users/:id/accept_invitation',
    element: (
      <SuspenseWrapper>
        <SingUp />
      </SuspenseWrapper>
    )
  },
  {
    path: '/companies/:companyId/contractor_companies/:id/accept_invitation',
    element: (
      <SuspenseWrapper>
        <SingUp />
      </SuspenseWrapper>
    )
  },
  {
    path: '/companies/:companyId/contractor_users/:id/accept_invitation',
    element: (
      <SuspenseWrapper>
        <SingUp />
      </SuspenseWrapper>
    )
  },
  {
    path: '/projects/:projectId/project_users/:id/accept_invitation',
    element: (
      <SuspenseWrapper>
        <SingUp />
      </SuspenseWrapper>
    )
  },
  {
    path: '/projects/:projectId/project_companies/:id/accept_invitation',
    element: (
      <SuspenseWrapper>
        <SingUp />
      </SuspenseWrapper>
    )
  }
];

export default protectedRoutes;
