/* eslint-disable consistent-return */
import axios from 'axios';
import Cookies from 'js-cookie';
import APICONST from '../constants';

const headers = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
};
const formHeaders = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  };
};
const getCSVHeader = () => {
  const token = Cookies.get('auth-token');
  return {
    Accept: 'text/csv',
    'Content-Type': 'text/csv',
    Authorization: `Bearer ${token}`
  };
};

const get = async (url, payload, csvHeader = false) => {
  return axios({
    method: 'get',
    headers: csvHeader ? getCSVHeader() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    params: payload
  })
    .then((response) => {
      const pageItems = response.headers?.['page-items'] || '';
      const totalCount = response.headers?.['total-count'] || '';
      const totalPages = response.headers?.['total-pages'] || '';
      const currentPage = response.headers?.['current-page'] || '';
      return { ...response, pagination: { pageItems, totalCount, totalPages, currentPage } };
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        throw e.message;
      } else {
        return e;
      }
    });
};
const post = async (url, payload, formHeader = false) => {
  return axios({
    headers: formHeader ? formHeaders() : headers(),
    method: 'post',
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};

const put = async (url, payload, formHeader = false) => {
  return axios({
    method: 'put',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};

const patch = async (url, payload, formHeader = false) => {
  return axios({
    method: 'patch',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      }
      return e;
    });
};
const deleteCall = async (url, payload, token = '') => {
  return axios({
    method: 'delete',
    headers: headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.error(e.message);
      } else {
        return e;
      }
    });
};

const postRequest = async (url, payload, formHeader = false) => {
  return axios({
    headers: formHeader ? formHeaders() : headers(),
    method: 'post',
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

const putRequest = async (url, payload, formHeader = false) => {
  return axios({
    method: 'put',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

const patchRequest = async (url, payload, formHeader = false) => {
  return axios({
    method: 'patch',
    headers: formHeader ? formHeaders() : headers(),
    url: APICONST.BASE_URL + `${url}`,
    data: payload
  }).then((response) => {
    return response;
  });
};

export const API_SERVICE = {
  post: post,
  get: get,
  put: put,
  patch: patch,
  delete: deleteCall,
  postRequest,
  putRequest,
  patchRequest
};

export default API_SERVICE;
