import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { API_SERVICE } from '../../services/CommonApi';
import PunchList from '../../services/PunchList';
import * as ACTIONS from '../../actions';
import { GET_SUBTASK } from '../../actions';

const initialState = {
  formData: {},
  view_filters: {},
  createPunchListSuccess: '',
  viewPunchList: {},
  response: {},
  viewSubTaskData: {},
  subEdit: false,
  selectedAssignees: [],
  selectedSubTaskAssignees: [],
  createResponseSuccess: false,
  createSubTaskSuccess: '',
  selectedVal: [],
  allActivityLogData: [],
  activityLogData: {},
  checkSuccess: false
};
export const onCreatePunchList = createAsyncThunk(
  ACTIONS.CREATE_PUNCHLIST,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.post(`${PunchList.createPunchList(arg.projectId)}`, arg.payload, true);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getPunchListFilters = createAsyncThunk(
  ACTIONS.VIEW_PUNCHLIST_FILTER,
  async (arg, thunkAPI) => {
    const url = arg.create_punch_list
      ? `${PunchList.getFilters(arg.projectId)}?create_punch_list=${arg.create_punch_list}`
      : PunchList.submittalFilters(arg.projectId);
    try {
      return API_SERVICE.get(url, '');
    } catch (err) {
      // try {
      //   return await API_SERVICE.get(`${PunchList.getFilters(arg)}`);
      // }
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const addResponse = createAsyncThunk(ACTIONS.ADD_RESPONSE, async (arg, thunkAPI) => {
  try {
    const response = await API_SERVICE.post(
      `${PunchList.addResponse(arg.projectId, arg.punchListId)}`,
      arg.payload,
      true
    );
    const obj = { projectId: arg.projectId, punchListId: arg.punchListId };
    thunkAPI.dispatch(getPunchListItem(obj));
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getPunchListItem = createAsyncThunk(ACTIONS.VIEW_PUNCHLIST, async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.get(`${PunchList.viewPunchList(arg.projectId, arg.punchListId)}`);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updatePunchList = createAsyncThunk(ACTIONS.UPDATE_PUNCHLIST, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.put(
      `${PunchList.viewPunchList(arg.projectId, arg.punchListId)}`,
      arg.payload,
      true
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createSubTask = createAsyncThunk(ACTIONS.CREATE_SUBTASK, async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.post(`${PunchList.createSubTask(arg.projectId)}`, arg.payload);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const updateSubTask = createAsyncThunk(ACTIONS.UPDATE_SUBTASK, async (arg, thunkAPI) => {
  try {
    return API_SERVICE[arg.deleteTask ? 'delete' : 'put'](
      `${PunchList.updateSubTask(arg.projectId, arg.subTaskId)}`,
      arg.payload
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const punchListHistoryLog = createAsyncThunk(
  ACTIONS.SHOW_ALL_ACTIVITY_LOG,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${PunchList.getPunchListLog(arg.projectId, arg.punchListId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const showActivityLog = createAsyncThunk(
  ACTIONS.SHOW_ACTIVITY_LOG,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(
        `${PunchList.viewPunchListLog(arg.projectId, arg.punchListId, arg.id)}`
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const CreateCheckList = createAsyncThunk(ACTIONS.CREATE_CHECKLIST, async (arg, thunkAPI) => {
  try {
    const payload = {
      check_list: {
        subject: arg.subject
      }
    };
    return await API_SERVICE.post(
      `${PunchList.createCheckList(arg.projectId, arg.punchListId)}`,
      payload
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const UpdateCheckList = createAsyncThunk(ACTIONS.UPDATE_CHECKLIST, async (arg, thunkAPI) => {
  try {
    const payload = {
      check_list: {
        subject: arg.subject
      }
    };
    return await API_SERVICE.put(
      `${PunchList.updateCheckList(arg.projectId, arg.punchListId, arg.id)}`,
      payload
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const DeleteCheckList = createAsyncThunk(ACTIONS.DELETE_CHECKLIST, async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.delete(
      `${PunchList.updateCheckList(arg.projectId, arg.punchListId, arg.id)}`
    );
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getSubTaskById = createAsyncThunk(ACTIONS.GET_SUBTASK, async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.get(`${PunchList.getSubTask(arg.projectId, arg.punchListId)}`);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const punchlistSlice = createSlice({
  name: 'punchlist',
  initialState,
  reducers: {
    resetPunchListStatus: (state, { payload }) => {
      return {
        ...state,
        createPunchListSuccess: '',
        createSubTaskSuccess: '',
        createResponseSuccess: false
      };
    },
    checkListStatusChange: (state, { payload }) => {
      return {
        ...state,
        checkSuccess: false
      };
    },
    resetPunchlist: (state, action) => {
      state.viewPunchList = {};
      state.selectedAssignees = [];
    },
    onChangeAssignee: (state, { payload }) => {
      state.selectedAssignees = payload;
    },
    onChangeSubTaskAssignee: (state, { payload }) => {
      state.selectedSubTaskAssignees = payload;
    },
    setSelectedField: (state, action) => {
      const payload = [];
      action.payload.map((el) =>
        state.view_filters.custom_fields.map((x) => x.key === el.key && payload.push(x))
      );
      state.selectedVal = [...payload];
    },
    removeSelectedField: (state, action) => {
      state.selectedVal = state.selectedVal.filter((x) => x.key !== action.payload.key);
    },
    setPunchListCustomFields: (state, { payload }) => {
      state.selectedVal = payload;
    },
    clearActivityLog: (state, { payload }) => {
      state.activityLogData = {};
    },
    onSetEditValues: (state, { payload }) => {
      state.viewSubTaskData = payload;
      state.subEdit = true;
    },
    onResetSubEdit: (state, { payload }) => {
      state.viewSubTaskData = {};
      state.subEdit = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(onCreatePunchList.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.formData = payload.data;
        state.createPunchListSuccess = 'Punchlist Created Successfully';
      }
    });
    builder.addCase(getPunchListFilters.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        // state.view_filters = payload.data;

        const obj = payload.data;
        obj.custom_fields = obj.custom_fields.map((el) => ({
          ...el,
          key: nanoid(),
          value: el.title,
          filedValue: '',
          label: el.title,
          title: el.input_type,
          type: el.input_type
        }));
        state.view_filters = obj;
      }
    });
    builder.addCase(addResponse.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.response = payload.data;
        state.createResponseSuccess = true;
      }
    });
    builder.addCase(updatePunchList.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.response = payload.data;
        state.createPunchListSuccess = 'Punchlist Updated Successfully';
      }
    });
    builder.addCase(getPunchListItem.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.viewPunchList = payload.data.data;
        state.createSubTaskSuccess = '';
      }
    });
    builder.addCase(getSubTaskById.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        // state.viewSubTaskData = payload.data.data;
        // state.subEdit = true;
      }
    });
    builder.addCase(punchListHistoryLog.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.allActivityLogData = payload.data.data;
      }
    });
    builder.addCase(showActivityLog.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.activityLogData = payload.data.data;
      }
    });
    builder.addCase(CreateCheckList.fulfilled, (state, { meta, payload }) => {
      console.log(payload.status, state.checkSuccess);
      if (payload.status === 200 || payload.status === 201) {
        state.checkSuccess = true;
      }
    });
    builder.addCase(UpdateCheckList.fulfilled, (state, { meta, payload }) => {
      console.log(payload.status, state.checkSuccess);
      if (payload.status === 200 || payload.status === 201) {
        state.checkSuccess = true;
      }
    });
    builder.addCase(DeleteCheckList.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.checkSuccess = true;
      }
    });
    builder.addCase(createSubTask.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.createSubTaskSuccess = 'created';
      }
    });
    builder.addCase(updateSubTask.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.viewSubTaskData = {};
        state.subEdit = false;
        state.createSubTaskSuccess = payload.data?.message ? 'deleted' : 'updated';
      }
    });
  }
});

export const {
  resetPunchListStatus,
  resetPunchlist,
  onChangeAssignee,
  onChangeSubTaskAssignee,
  setSelectedField,
  removeSelectedField,
  checkListStatusChange,
  clearActivityLog,
  onResetSubEdit,
  onSetEditValues
} = punchlistSlice.actions;
export default punchlistSlice.reducer;
