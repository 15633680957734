import { Col, Row, Form, Input, Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import frame from '../../images/Frame.svg';
import group from '../../images/Group.svg';
import APICONST from '../../constants';

const Login = () => {
  const navigate = useNavigate();
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!'
    }
  };

  const onFinish = async (values) => {
    const url = `${APICONST.LOGIN}`;

    const params = {
      user: {
        email: values.email,
        password: values.password,
        push_token: '',
        platform_name: 'web'
      }
    };

    const response = await axios.post(url, params);
    if (response) {
      console.log(response);
      Cookies.set('auth-token', response.data.auth_token);
      Cookies.set('company_id', response.data.user.company.unique_reference_id);
      Cookies.set('company_name', response.data.user.company.name);
      Cookies.set('user', values.email);
      Cookies.set('user_id', response.data.user.unique_reference_id);
      navigate('/dashboard');
    }
  };
  return (
    <div className="login">
      <Row>
        <Col span={16}>
          <img src={frame} className="login-banner" />
        </Col>
        <Col span={8} className="login-content">
          <img className="group-img" src={group} />
          <h2>Log in to your Account</h2>
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            validateMessages={validateMessages}>
            <Form.Item name="email" label="User Name/Email" rules={[{ required: true }]}>
              <Input placeholder="User Name" />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <p style={{ paddingBottom: '5px', textAlign: 'right' }}>
                <a>Forget your password ?</a>
              </p>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ height: '42px' }}>
                Login
              </Button>
              <p style={{ padding: '10px 0' }}>
                Don't have an account ? <a onClick={() => navigate('/signup')}>Click Here</a>
              </p>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
