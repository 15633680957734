import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import companyBanner from '../../images/company-pg-banner.png';
import group from '../../images/Group.svg';
import { SignupWrapper } from './StyleComponent';
import { createUser, resetErrors } from '../../features/LoginSlice';
import Success from './Success';
// import { API_SERVICE } from '../../services/CommonApi';
import APICONST from '../../constants';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 24,
      offset: 0
    }
  }
};

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { errors = [], registerSuccess = false } = useSelector((state) => state.root.signUp);
  const [form] = Form.useForm();
  const [invite, setInvite] = useState(false);
  const [signupPage, setSignup] = useState(false);

  useEffect(() => {
    if (location) {
      const path = location.pathname.split('/');
      console.log(path, path[path.length - 1], location.pathname);
      if (path[path.length - 1] === 'accept_invitation') {
        console.log(path, path[path.length - 1], location.pathname);
        axios.put(APICONST.BASE_URL + location.pathname, {}).then((r) => {
          if (r.status === 200) setInvite(true);
        });
      } else {
        setSignup(true);
      }
    }
  }, []);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        dispatch(resetErrors());
      }, 5000);
    }
  }, [errors]);
  const onFinish = (values) => {
    const payload = {
      company: {
        name: values.name,
        users_attributes: [
          {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            username: values.username,
            password: values.password,
            password_confirmation: values.password_confirmation
          }
        ]
      }
    };
    dispatch(createUser({ payload }));
  };
  return (
    <>
      <div className="login">
        <SignupWrapper>
          <Row>
            <Col span={16}>
              <img src={companyBanner} className="login-banner" alt="" />
            </Col>
            <Col span={8} className="login-content">
              {registerSuccess || invite ? (
                <>
                  <Success invite={invite} />
                </>
              ) : (
                signupPage && (
                  <>
                    <img className="group-img" src={group} alt="" />
                    <h2>Sign Up</h2>
                    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
                      <Row gutter={[8, 8]}>
                        <Col span={12}>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[{ required: true, message: 'Please input your  First name' }]}>
                            <Input placeholder="First Name" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="last_name"
                            label="Last Name"
                            rules={[{ required: true, message: 'Please input your  Last name' }]}>
                            <Input placeholder="Last Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="name"
                            label="Company Name"
                            rules={[{ required: true, message: 'Please input your Company name' }]}>
                            <Input placeholder="Company Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        <Col span={12}>
                          <Form.Item
                            name="username"
                            label="User Name"
                            rules={[{ required: true, message: 'Please input your user name' }]}>
                            <Input placeholder="User Name" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: 'email',
                                message: 'The input is not valid E-mail!'
                              },
                              {
                                required: true,
                                message: 'Please input your E-mail!'
                              }
                            ]}>
                            <Input placeholder="Email" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        <Col span={12}>
                          <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your password!'
                              }
                            ]}
                            hasFeedback>
                            <Input.Password placeholder="Confirm Password" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="password_confirmation"
                            label="Confirm Password"
                            dependencies={['password']}
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password!'
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('The new password that you entered do not match!')
                                  );
                                }
                              })
                            ]}>
                            <Input.Password placeholder="Confirm Password" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(new Error('Should accept agreement'))
                          }
                        ]}
                        {...tailFormItemLayout}>
                        <Checkbox>
                          I have read and Agree to the <a href="">terms and conditions</a>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item className="signup-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          style={{ height: '42px' }}>
                          Sign up
                        </Button>
                        <p style={{ padding: '10px 0' }}>
                          Already have an account? <a onClick={() => navigate('/login')}>Sign in</a>
                        </p>
                      </Form.Item>
                    </Form>
                    {errors &&
                      errors.length > 0 &&
                      errors.map((e, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              style={{ display: 'flex', flexWrap: 'nowrap' }}
                              className="d-flex flex-row gap-16 item-center">
                              <div
                                id={i}
                                className="ant-form-item-explain ant-form-item-explain-connected"
                                role="alert">
                                <div className="ant-form-item-explain-error">{e}</div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                )
              )}
            </Col>
          </Row>
        </SignupWrapper>
      </div>
    </>
  );
}

export default SignUp;
