import React from 'react';
import HomePageHeroSection from './Components/HomePageHeroSection';
import FeaturesSection from './Components/FeaturesSection';
import ParallaxSection from './Components/ParallaxSection';
import DemoSection from './Components/DemoSection';
import FeatureHighlightSection from './Components/FeatureHighlightSection';

export default function Website() {
	return (
		<>
			<HomePageHeroSection />
			<FeaturesSection
				headerText={"ProjectLoop Works With Industry Leaders"}
				subText={""}
				featureHighlights={["ProjectLoop seamlessly integrates contractors, subcontractors, vendors, and suppliers into a unified safety ecosystem, providing a holistic approach to safety management. This integration ensures complete oversight, allowing you to efficiently manage and monitor your safety management system with ease, and in real time.",
					"Operational efficiency and regulatory compliance are at your fingertips with ProjectLoop's tailored solutions. Specifically designed for safety leaders, our platform simplifies the complexities of safety management, putting an end to the constant chase for compliance. With ProjectLoop, you can now focus on what truly matters—proactive safety measures.",
					"	Take control of construction protocols with ProjectLoop, where empowerment meets precision. Partner with us to ensure end-to-end user risk management, creating a workplace that is not only more productive but fundamentally safer and more secure. At ProjectLoop, we are dedicated to putting the power of safety back into your hands."]}
				sectionBackground='dark'
				sectionAlign="image-right"
			/>
			<FeaturesSection
				headerText={"ProjectLoop Works With Industry Leaders"}
				subText={""}
				featureHighlights={["ProjectLoop seamlessly integrates contractors, subcontractors, vendors, and suppliers into a unified safety ecosystem, providing a holistic approach to safety management. This integration ensures complete oversight, allowing you to efficiently manage and monitor your safety management system with ease, and in real time.",
					"Operational efficiency and regulatory compliance are at your fingertips with ProjectLoop's tailored solutions. Specifically designed for safety leaders, our platform simplifies the complexities of safety management, putting an end to the constant chase for compliance. With ProjectLoop, you can now focus on what truly matters—proactive safety measures.",
					"	Take control of construction protocols with ProjectLoop, where empowerment meets precision. Partner with us to ensure end-to-end user risk management, creating a workplace that is not only more productive but fundamentally safer and more secure. At ProjectLoop, we are dedicated to putting the power of safety back into your hands."]}
			/>
			<FeaturesSection
				headerText={"ProjectLoop Works With Industry Leaders"}
				subText={""}
				featureHighlights={["ProjectLoop seamlessly integrates contractors, subcontractors, vendors, and suppliers into a unified safety ecosystem, providing a holistic approach to safety management. This integration ensures complete oversight, allowing you to efficiently manage and monitor your safety management system with ease, and in real time.",
					"Operational efficiency and regulatory compliance are at your fingertips with ProjectLoop's tailored solutions. Specifically designed for safety leaders, our platform simplifies the complexities of safety management, putting an end to the constant chase for compliance. With ProjectLoop, you can now focus on what truly matters—proactive safety measures.",
					"	Take control of construction protocols with ProjectLoop, where empowerment meets precision. Partner with us to ensure end-to-end user risk management, creating a workplace that is not only more productive but fundamentally safer and more secure. At ProjectLoop, we are dedicated to putting the power of safety back into your hands."]}
				sectionBackground='dark'
				sectionAlign="image-right"
			/>
			<FeatureHighlightSection />
			<ParallaxSection />
			<DemoSection mergeTop={false} />
		</>
	)
}
