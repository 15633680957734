import React from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import successIcon from '../../images/Success.svg';

function Success({ invite }) {
  const navigate = useNavigate();
  const token = Cookies.get('auth-token');

  return (
    <>
      <Row align="middle" style={{ textAlign: 'center', display: 'block' }}>
        <Col>
          <img
            src={successIcon}
            style={{
              verticalAlign: 'middle'
            }}
          />
          <h1 className="text-center">
            {invite ? 'Thanks for joining' : 'Registration Completed Successfully'}
          </h1>
          <p className="text-center">
            {invite ? '' : 'Please check your registered email for email verification'}
          </p>
          {token ? (
            <Button
              type="primary"
              className="login-form-button"
              onClick={() => navigate('/dashboard')}
              style={{ width: 'auto', height: '35px' }}>
              Goto site
            </Button>
          ) : (
            <Button
              type="primary"
              className="login-form-button"
              onClick={() => navigate('/login')}
              style={{ width: 'auto', height: '35px' }}>
              Return to Login page
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Success;
