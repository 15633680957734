import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../../actions';
import drawings from '../../services/Drawing';
import { API_SERVICE } from '../../services/CommonApi';

const initialState = {
  drawingAreas: [],
  editArea: {},
  savePDFCoordinate: {},
  annotateCoordinates: null,
  author: false,
  pdf_auto_scan_attrs: {
    coordinates_defined: false,
    auto_scan_initiated: false,
    auto_scan_completion: false
  },
  drawingList: [],
  drawingPendingList: [],
  drawingSetList: [],
  uploadLogs: [],
  existingDrawingSet: [],
  approvalDrawing: false,
  fileName: '',
  title: '-',
  number: '-',
  titleCoordinates: null,
  numberCoordinates: null
};

let deleteID = '';

export const addDrawingArea = createAsyncThunk(ACTIONS.ADD_DRAWING_AREA, async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.post(`${drawings.addDrawingArea(arg.projectId)}`, arg.payload, '');
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getDrawingAreas = createAsyncThunk(
  ACTIONS.VIEW_DRAWING_AREA_LIST,
  async (arg, thunkAPI) => {
    console.log(drawings?.getDrawingAreaList(), arg, 'API_SERVICE');
    try {
      return await API_SERVICE.get(`${drawings.getDrawingAreaList(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getDrawingVersionSummary = createAsyncThunk(
  'DRAWING/VERSION_SUMMARY',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.drawingVersionSummary(arg.id)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editDrawingArea = createAsyncThunk(
  ACTIONS.EDIT_DRAWING_AREA,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.getEditArea(arg.projectId, arg.editID)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateDrawingArea = createAsyncThunk(
  ACTIONS.UPDATE_DRAWING_AREA,
  async (arg, thunkAPI) => {
    try {
      const response = await API_SERVICE.patch(
        `${drawings.updateDrawingArea(arg.projectId, arg.editID)}`,
        arg.payload
      );
      thunkAPI.dispatch(getDrawingAreas());
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteDrawingArea = createAsyncThunk(
  ACTIONS.DELETE_DRAWING_AREA,
  async (arg, thunkAPI) => {
    console.log(arg, 'deleeeeee');
    try {
      const response = await API_SERVICE.delete(
        `${drawings.deleteDrawingArea(arg.projectId, arg.deleteID)}`
      );
      deleteID = arg.deleteID;
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getDrawingList = createAsyncThunk(
  'DRAWING/VIEW_DRAWING_LIST',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.getDrawingList(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getDrawingSetList = createAsyncThunk(
  'DRAWING/VIEW_DRAWING_SET_LIST',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.getDrawingSetList(arg.projectId)}`, {
        drawing_discipline_id: arg.discipline_id,
        drawing_set_id: arg.set_id
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveUploadDrawing = createAsyncThunk(
  'DRAWING/CREATE_DRAWING_UPLOAD',
  async (arg, thunkAPI) => {
    //   console.log(drawings?.getDrawingAreaList(), arg, 'API_SERVICE');
    try {
      return await API_SERVICE.post(
        `${drawings.getDrawingUploadLog(arg.projectId)}`,
        arg.formData,
        true
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getExistingDrawingSet = createAsyncThunk(
  'DRAWING/EXISTING_DRAWING_SET',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.loadDrawingSet(arg.companyId, arg.id)}`, {
        project_id: arg.projectId
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getUploadLogs = createAsyncThunk(
  ACTIONS.VIEW_UPLOAD_LOG_LIST,
  async (arg, thunkAPI) => {
    //   console.log(drawings?.getDrawingAreaList(), arg, 'API_SERVICE');
    try {
      return await API_SERVICE.get(`${drawings.getDrawingUploadLog(arg.projectId)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const drawingApproval = createAsyncThunk(
  'DRAWING/DRAWING_APPROVAL',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.put(
        `${drawings.uploadDrawingApproval(arg.projectId, arg.uploadId)}`
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const drawingPendingApproval = createAsyncThunk(
  'DRAWING/DRAWING_PENDING',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.pendingDrawingApproval(arg.projectId, arg.id)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const processAutoScan = createAsyncThunk(
  'DRAWING/PROCESS_AUTO_SCAN',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.put(`${drawings.pdfAutoScanProcess(arg.projectId, arg.id)}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const savePDFCoordinates = createAsyncThunk(
  'DRAWING/SAVE_COORDINATES',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.put(
        `${drawings.saveCoordinates(arg.projectId, arg.id)}`,
        arg.payload
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveSheetDetails = createAsyncThunk(
  'DRAWING/saveSheetDetails',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.patch(`${drawings.saveSheetDetails(arg.id)}`, arg.payload);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const scanOCRExtraction = createAsyncThunk(
  'DRAWING/scanOCRExtraction',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(`${drawings.processOCRExtraction(arg.id)}`, {});
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const saveAnnotations = createAsyncThunk('DRAWING/saveExportView', async (arg, thunkAPI) => {
  try {
    return await API_SERVICE.post(`${drawings.saveAnnotations(arg.id)}`, arg.formData, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const loadAnnotations = createAsyncThunk(
  'DRAWING/loadAnnotations',
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${drawings.loadAnnotations(arg.id)}`, {});
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const drawingSlice = createSlice({
  name: 'drawing',
  initialState,
  reducers: {
    setDrawingFileName: (state, { payload }) => {
      state.fileName = payload;
    },
    resetDrawingPendingList: (state, { payload }) => {
      state.drawingPendingList = [];
      state.annotateCoordinates = null;
    },
    setAnnotateCoordinates: (state, { payload }) => {
      // eslint-disable-next-line no-debugger
      // debugger;
      if (payload?.type) {
        if (payload.type === 'Number') state.numberCoordinates = payload.coordinates;
        if (payload.type === 'Title') state.titleCoordinates = payload.coordinates;
      }
    },
    setTitleNumber: (state, { payload }) => {
      state.title = payload.title;
      state.number = payload.number;
    },
    resetTitleNumber: (state, { payload }) => {
      state.title = '-';
      state.number = '-';
    },
    onChangeRowValues: (state, { payload }) => {
      state.drawingPendingList = state.drawingPendingList.map((v) =>
        v.unique_reference_id === payload.unique_reference_id
          ? { ...v, [payload.name]: payload.value, currentField: true }
          : { ...v, currentField: false }
      );
    }
    // setTitleCoordinates: (state, { payload }) => {
    //   state.titleCoordinates = payload;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(addDrawingArea.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingAreas = [...state.drawingAreas, payload.data.data];
      }
    });
    builder.addCase(getDrawingAreas.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingAreas = payload.data.data;
      }
    });
    builder.addCase(updateDrawingArea.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        const temp = state.drawingAreas.filter((e) => e.id !== payload.data.data.id);
        state.drawingAreas = [...temp, payload.data.data];
      }
    });
    builder.addCase(editDrawingArea.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.editArea = payload.data.data;
      }
    });
    builder.addCase(saveSheetDetails.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.rowUpdate = 'Drawing Review updated successfully';
      }
    });
    builder.addCase(scanOCRExtraction.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.rowUpdate = 'Drawing Review scan updated successfully';
      }
    });
    builder.addCase(getDrawingList.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingList = payload.data;
      }
    });
    builder.addCase(drawingPendingApproval.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingPendingList = payload.data.drawings;
        state.pdf_auto_scan_attrs = payload.data.pdf_auto_scan_attrs;
      }
    });
    builder.addCase(savePDFCoordinates.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.savePDFCoordinate = payload.data;
      }
    });
    builder.addCase(processAutoScan.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.autoScan = payload.data;
      }
    });
    builder.addCase(getDrawingSetList.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingSetList = payload.data;
      }
    });
    builder.addCase(deleteDrawingArea.fulfilled, (state, { payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.drawingAreas = state.drawingAreas.filter((e) => e.id !== deleteID);
      }
    });
    builder.addCase(getUploadLogs.fulfilled, (state, { payload }) => {
      if (payload.status === 200) {
        state.uploadLogs = payload.data.data;
      }
    });
    builder.addCase(drawingApproval.fulfilled, (state, { payload }) => {
      if (payload.status === 200) {
        state.approvalDrawing = payload.data.data;
      }
    });
    builder.addCase(getExistingDrawingSet.fulfilled, (state, { payload }) => {
      if (payload.status === 200) {
        state.existingDrawingSet = payload.data;
      }
    });
  }
});
export const {
  setDrawingFileName,
  resetDrawingPendingList,
  setAnnotateCoordinates,
  setTitleNumber,
  resetTitleNumber,
  onChangeRowValues
} = drawingSlice.actions;
export default drawingSlice.reducer;
