import React from 'react';
import "./styles.scss";

import logo from "../../../../images/pl-logo-white.svg"
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className='pl-footer'>
            <div>
                <Link to="/terms">Terms & Conditions</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/disclaimer">Disclaimer</Link>
            </div>
            <img src={logo} alt="footer-image" />
            <div>Copyright 2023 © ProjectLoop, Inc. All rights reserved.</div>
        </div>
    )
}
