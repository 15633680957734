import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ACTIONS from '../actions';
import { API_SERVICE } from '../services/CommonApi';
import projectAPI from '../services/Project';

const initialState = {
  projectDetails: {},
  project: {},
  projectEditDetails: {},
  editData: {},
  crewBaseData: {}
};

export const getProjectDetails = createAsyncThunk(ACTIONS.VIEW_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.get(projectAPI.getProjectDetails(arg.projectId));
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const createProject = createAsyncThunk(ACTIONS.CREATE_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.post(projectAPI.createProject(), arg.payload, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const updateProject = createAsyncThunk(ACTIONS.UPDATE_PROJECT, async (arg, thunkAPI) => {
  try {
    return API_SERVICE.put(projectAPI.updateProject(arg.id), arg.payload, true);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getProjectEditDetails = createAsyncThunk(
  ACTIONS.EDIT_PROJECT,
  async (arg, thunkAPI) => {
    try {
      return API_SERVICE.get(projectAPI.getProjectEditDetails(arg.projectId));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetStatus: () => initialState,
    setEditData: (state, { payload }) => {
      state.editData = payload;
    },
    setCrewBaseData: (state, { payload }) => {
      state.crewBaseData = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectDetails.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.projectDetails = payload.data.data;
      }
    });
    builder.addCase(createProject.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.project = payload.data.data;
      }
    });
    builder.addCase(updateProject.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.project = payload.data.data;
      }
    });
    builder.addCase(getProjectEditDetails.fulfilled, (state, { meta, payload }) => {
      if (payload.status === 200 || payload.status === 201) {
        state.projectEditDetails = payload.data.data;
      }
    });
  }
});

export const { resetStatus, setEditData, setCrewBaseData } = projectSlice.actions;
export default projectSlice.reducer;
