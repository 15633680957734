import React from 'react';
import { Button } from 'antd';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./styles.scss";

import HeroBannerImage from "../../../images/HeroBannerImage.png";
import HeroBannerImage1 from "../../../images/HeroBannerImage1.png";
import HeroBannerImage2 from "../../../images/HeroBannerImage2.png";
import HeroBannerImage3 from "../../../images/HeroBannerImage3.png";
import HeroBannerImage4 from "../../../images/HeroBannerImage4.png";
import { Link } from 'react-router-dom';

export default function HomePageHeroSection() {
    return (
        <div className="hero-section-container">
            <div className="text-content">
                <h2 className="hero-text">Welcome to ProjectLoop</h2>
                <p className="hero-subtext2">Your Cutting-Edge Construction Management Platform.</p>
                <div className="hero-content">
                    <p className="hero-content-text">
                        Explore a revolutionary approach to construction management with ProjectLoop's advanced software, blending innovation with decades of expertise in the field. Embark on a journey into a new era of construction efficiency with our state-of-the-art platform and seamlessly integrated ecosystem.
                        <br />
                        <br />
                        With over many decades of experience in advanced construction management systems, we've pioneered a solution to transform project oversight.
                        <br />
                        <br />
                        At ProjectLoop, we take pride in simplifying construction processes, providing a comprehensive software suite supported by a robust ecosystem. From streamlined project and user management to real-time monitoring, our tailored solutions are crafted to elevate efficiency standards across the construction industry.
                    </p>
                </div>
                <div className="hero-button-row">
                    <a href="#">
                        <Button type="primary" size='large' shape='round' className="book-demo-btn">Book A Demo</Button>
                    </a>
                    <Link to="/features">
                        <Button className="all-features-btn">See all features</Button>
                    </Link>
                </div>
            </div>
            <div className="image-content">
                <Carousel
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3500}
                    stopOnHover={false}
                    swipeable={false}
                    transitionTime={300}
                    animationHandler="fade"
                >
                    <div>
                        <img src={HeroBannerImage} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage1} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage2} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage3} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage4} alt="hero-image" className="hero-image" />
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
