import React, { useEffect, useState } from 'react';
import { Breadcrumb, Select, Avatar, Dropdown, Button, Tooltip } from 'antd';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { LeftOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { ReactComponent as Logo } from '../../fonts/logo.svg';
import Profile from '../../images/profile.png';
import { ReactComponent as Industry } from '../../fonts/industry.svg';
import HeaderWrapper from './styles';
import { getProjectDetails } from '../../features/projectSlice';
import { useGetCompanyProjectsDropDown } from '../../services/Company/hooks';

const logout = () => {
  Cookies.remove('auth-token');
  Cookies.remove('user');
  Cookies.remove('company_id');
  Cookies.remove('company_name');
  window.location.href = '/';
};

const proMenu = [
  {
    label: (
      <Link to="/user-profile">
        <span>Profile Settings</span>
      </Link>
    ),
    icon: <SettingOutlined />,
    key: '0'
  },
  {
    label: (
      <Link to="/company/settings">
        <span>{` Company Settings`}</span>
      </Link>
    ),
    icon: <SettingOutlined />,
    key: '1'
  },
  {
    type: 'divider'
  },
  {
    label: (
      <Button style={{ padding: 0, color: 'rgba(0, 0, 0, 0.8)' }} onClick={logout} type="link">
        Sign Out
      </Button>
    ),
    icon: <LogoutOutlined />,
    key: '2'
  }
];

export default function CommonHeader({ onlyUserInfo = false, isSurvey }) {
  const { projectId, companyId, id } = useParams();
  const { pathname, state: type } = useLocation();
  const userName = Cookies.get('user');
  const queryClient = useQueryClient();
  const projectDetails = useSelector((s) => s.root.project.projectDetails);
  const companyObject = JSON.parse(localStorage.getItem('company')) || {};
  // const { data, isLoading } = useGetCompanyProjectsList(companyObject?.unique_reference_id);
  const {
    data: dropdownData,
    isLoading,
    refetch
  } = useGetCompanyProjectsDropDown(
    companyObject?.unique_reference_id,
    type === 'member_projects' ? { type } : {}
  );
  const [selectedProject, setSelectedProject] = useState(projectId);
  const [projectList, setProjectList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onProjectChange = (values) => {
    setSelectedProject(values);
    navigate(`/projects/${values}`, { state: type });
  };

  useEffect(() => {
    if (projectId) {
      setSelectedProject(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    console.log(type, '  ====');
    refetch();
  }, [type]);

  useEffect(() => {
    if (projectDetails?.unique_reference_id) {
      queryClient.setQueryData(['projects-detail', projectDetails?.unique_reference_id], {
        data: { data: projectDetails }
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    if (companyId) {
      setSelectedProject(null);
    }
  }, [companyId]);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getProjectDetails({ projectId: selectedProject }));
    }
  }, [selectedProject]);

  useEffect(() => {
    if (dropdownData?.data?.projects?.length) {
      const newData = dropdownData?.data?.projects?.map((e) => {
        e.label = e.key;
        e.key = `${e.key} - ${e.value}`;
        return e;
      });
      setProjectList(newData);
    }
  }, [dropdownData]);

  // useEffect(() => {
  //   if (companyObject?.unique_reference_id) {
  //     try {
  //       const token = Cookies.get('auth-token');
  //       const fetchData = async () => {
  //         const response = await fetch(
  //           `${APICONST.COMPANY_PROJECTS_API}?company_id=${companyObject.unique_reference_id}`,
  //           {
  //             method: 'GET',
  //             headers: {
  //               'Content-type': 'application/json',
  //               Authorization: `Bearer ${token}`
  //             }
  //           }
  //         );

  //         const result = await response.json();
  //         if (result?.data) {
  //           const newData = result.data.map((e) => {
  //             e.label = e.name;
  //             e.value = e.unique_reference_id;
  //             return e;
  //           });
  //           setProjectList(newData);
  //         }
  //       };
  //       fetchData();
  //     } catch (err) {
  //       console.log('error==>', err);
  //     }
  //   }
  // }, [companyObject?.unique_reference_id]);

  const navigates = (value) => {
    if (value) {
      navigate(`/${value}/${projectId}`, { state: type });
    }
  };

  const items = [
    {
      label: (
        <div className="shortcut-options">
          <h2 className="title">APPS</h2>
          <ul className="shortcut-list">
            <li
              onClick={() => navigates('drawing')}
              className={pathname.includes('drawing') ? 'active' : 'inactive'}>
              <i className="icon icon-drawings" />
              <p>Drawings</p>
            </li>
            {/* <li
              onClick={() => navigates('drawings-list')}
              className={pathname.includes('drawings-list') ? 'active' : 'inactive'}>
              <i className="icon icon-drawings" />
              <p>Drawings</p>
            </li> */}
            {/* <li
              onClick={(e) => navigates('photo')}
              className={pathname.includes('photo') ? 'active' : 'inactive'}>
              <i className="icon icon-photos" />
              <p>Photos</p>
            </li> */}
            <li
              onClick={(e) => navigates('rfi')}
              className={pathname.includes('rfi') ? 'active' : 'inactive'}>
              <i className="icon icon-rfi" />
              <p>RFIs</p>
            </li>
            <li
              onClick={(e) => navigates('submittals')}
              className={pathname.includes('submittals') ? 'active' : 'inactive'}>
              <i className="icon icon-submittal" />
              <p>Submittals</p>
            </li>
            <li
              onClick={(e) => navigates('punch-list')}
              className={pathname.includes('punch-list') ? 'active' : 'inactive'}>
              <i className="icon icon-punch-list" />
              <p>Punch List</p>
            </li>
            <li
              onClick={(e) => navigates('directory')}
              className={pathname.includes('directory') ? 'active' : 'inactive'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <g clipPath="url(#clip0_6488_103637)">
                  <path
                    d="M20 6V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H8C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V6C6 5.46957 6.21071 4.96086 6.58579 4.58579C6.96086 4.21071 7.46957 4 8 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6Z"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 16H16"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 11C11 11.5304 11.2107 12.0391 11.5858 12.4142C11.9609 12.7893 12.4696 13 13 13C13.5304 13 14.0391 12.7893 14.4142 12.4142C14.7893 12.0391 15 11.5304 15 11C15 10.4696 14.7893 9.96086 14.4142 9.58579C14.0391 9.21071 13.5304 9 13 9C12.4696 9 11.9609 9.21071 11.5858 9.58579C11.2107 9.96086 11 10.4696 11 11Z"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 8H7"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 12H7"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 16H7"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6488_103637">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Directory</p>
            </li>
            <li
              onClick={(e) => navigates('daily-logs')}
              className={pathname.includes('daily-logs') ? 'active' : 'inactive'}>
              <i className="icon icon-log" />
              <p>Daily Log</p>
            </li>
            <li
              onClick={(e) => navigates('projects-document')}
              className={pathname.includes('projects-document') ? 'active' : 'inactive'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <g clipPath="url(#clip0_6488_103654)">
                  <path
                    d="M5 4H9L12 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V17C21 17.5304 20.7893 18.0391 20.4142 18.4142C20.0391 18.7893 19.5304 19 19 19H5C4.46957 19 3.96086 18.7893 3.58579 18.4142C3.21071 18.0391 3 17.5304 3 17V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6488_103654">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Documents</p>
            </li>
            <li
              onClick={(e) => navigates('schedule')}
              className={pathname.includes('schedule') ? 'active' : 'inactive'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <g clipPath="url(#clip0_8488_151377)">
                  <path
                    d="M11.795 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V11"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 18C14 19.0609 14.4214 20.0783 15.1716 20.8284C15.9217 21.5786 16.9391 22 18 22C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18C22 16.9391 21.5786 15.9217 20.8284 15.1716C20.0783 14.4214 19.0609 14 18 14C16.9391 14 15.9217 14.4214 15.1716 15.1716C14.4214 15.9217 14 16.9391 14 18Z"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 3V7"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 3V7"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 11H19"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 16.4961V18.0001L19 19.0001"
                    stroke="#080808"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8488_151377">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Schedule</p>
            </li>
            <li
              onClick={(e) => navigates('actions')}
              className={pathname.includes('actions') ? 'active' : 'inactive'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <g clipPath="url(#clip0_9211_28964)">
                  <path
                    d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 3H11C9.89543 3 9 3.89543 9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3Z"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.87799 11.6143L9.30587 11.6143"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.87799 16.4961L9.30587 16.4961"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 11.1698L13 12.1698L15.3954 9.77441"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16.0507L13 17.0507L15.3954 14.6553"
                    stroke="#080808"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9211_28964">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Action</p>
            </li>
            {/* <li
              onClick={(e) => navigates('observe')}
              className={pathname.includes('observe') ? 'active' : 'inactive'}>
              <i className="icon icon-observations" />
              <p>Observations</p>
            </li>
            <li
              onClick={(e) => navigates('specify')}
              className={pathname.includes('specify') ? 'active' : 'inactive'}>
              <i className="icon icon-specifications" />
              <p>Specifications</p>
            </li>
            <li
              onClick={(e) => navigates('schedule')}
              className={pathname.includes('schedule') ? 'active' : 'inactive'}>
              <i className="icon icon-schedule" />
              <p>Schedule</p>
            </li>
            <li
              onClick={(e) => navigates('email')}
              className={pathname.includes('email') ? 'active' : 'inactive'}>
              <i className="icon icon-mail" />
              <p>Emails</p>
            </li> */}
          </ul>

          <h2 className="title">MANAGE</h2>
          <ul className="shortcut-list">
            <li
              onClick={(e) => navigate(`/projects/${projectId}/settings`)}
              className={pathname.includes('settings') ? 'active' : 'inactive'}>
              <i className="icon icon-settings" />
              <p>Settings</p>
            </li>
          </ul>
        </div>
      ),
      key: '0'
    }
  ];

  return (
    <HeaderWrapper>
      <Logo
        className="logo-img"
        onClick={() => {
          navigate('/dashboard');
        }}
      />
      {onlyUserInfo && (
        <>
          <Breadcrumb separator={<RightOutlined />} className="crumbs">
            <Breadcrumb.Item className="crumbs-item">
              <div className="icon">
                <Industry />
              </div>
              <span className="p-name">{Cookies.get('company_name')}</span>
              {isSurvey && (
                <Button type="link" icon={<LeftOutlined />} onClick={() => navigate('/survey')}>
                  Back to Template
                </Button>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </>
      )}
      {!onlyUserInfo && !isLoading && (
        <Breadcrumb separator={<RightOutlined />} className="crumbs">
          <Breadcrumb.Item className="crumbs-item">
            <div className="icon">
              <Industry />
            </div>
            <span className="p-name">{companyObject.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="crumbs-item">
            <Tooltip
              placement="left"
              title={`Go to the ${type === 'member_projects' ? 'Associated' : ''} Project List`}>
              <div
                className="icon"
                onClick={() => navigate(`/projects-list/${companyObject.unique_reference_id}`)}>
                {type === 'member_projects' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <g opacity="0.5">
                      <path
                        d="M8 8V6C8 5.46957 8.21071 4.96086 8.58579 4.58579C8.96086 4.21071 9.46957 4 10 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V8"
                        stroke="#FC8543"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 11.5V10C20 8.89543 19.1046 8 18 8H6C4.89543 8 4 8.89543 4 10V18C4 19.1046 4.89543 20 6 20H11.5"
                        stroke="#FC8543"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 20L20 15"
                        stroke="#FC8543"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 19.5V15H15.5"
                        stroke="#FC8543"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.3838 16.873V11.8452C10.3838 11.6547 10.4595 11.472 10.5942 11.3373C10.7289 11.2026 10.9116 11.127 11.1021 11.127H12.1794C12.5604 11.127 12.9258 11.2783 13.1952 11.5477C13.4646 11.8171 13.616 12.1825 13.616 12.5635C13.616 12.9445 13.4646 13.3099 13.1952 13.5793C12.9258 13.8487 12.5604 14 12.1794 14H11.1021"
                        stroke="#FC8543"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <g opacity="0.5" clipPath="url(#clip0_9492_10364)">
                      <path
                        d="M8 8V6C8 5.46957 8.21071 4.96086 8.58579 4.58579C8.96086 4.21071 9.46957 4 10 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V8"
                        stroke="#FC8543"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 8H6C4.89543 8 4 8.89543 4 10V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8Z"
                        stroke="#FC8543"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.3838 16.873V11.8452C10.3838 11.6547 10.4595 11.472 10.5942 11.3373C10.7289 11.2026 10.9116 11.127 11.1021 11.127H12.1794C12.5604 11.127 12.9258 11.2783 13.1952 11.5477C13.4646 11.8171 13.616 12.1825 13.616 12.5635C13.616 12.9445 13.4646 13.3099 13.1952 13.5793C12.9258 13.8487 12.5604 14 12.1794 14H11.1021"
                        stroke="#FC8543"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_9492_10364">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            </Tooltip>

            <Select
              defaultValue={projectId}
              onChange={onProjectChange}
              bordered={false}
              value={selectedProject}
              placeholder={`Select ${type === 'member_projects' ? 'Associated' : 'My'} Projects`}
              options={projectList}
            />
          </Breadcrumb.Item>
        </Breadcrumb>
      )}

      <ul className="header-menu">
        <li>
          <div className="profile">
            <div className="details">
              <h3>{userName}</h3>
              <p>User</p>
            </div>
            <div className="avatar-img">
              <Dropdown menu={{ items: proMenu }} placement="bottom" trigger={['click']}>
                <Avatar size="large" src={Profile} />
              </Dropdown>
            </div>
          </div>
        </li>

        <li className="alerts">
          <i className="icon icon-notification" />
        </li>

        {projectId && !onlyUserInfo && (
          <li>
            <Dropdown
              overlayClassName="apps-menu-wrap"
              menu={{ items }}
              placement="bottom"
              trigger={['click']}>
              <i className="icon icon-options" />
            </Dropdown>
          </li>
        )}

        {id && !projectId && (
          <li>
            <Dropdown
              overlayClassName="apps-menu-wrap"
              menu={{ items }}
              placement="bottom"
              trigger={['click']}>
              <i className="icon icon-options" />
            </Dropdown>
          </li>
        )}
      </ul>
    </HeaderWrapper>
  );
}
