const getPunchList = (projectId) => `projects/${projectId}/punch_lists`;
const punchListFilters = (projectId) => `projects/${projectId}/punch_lists/filters`;
const createPunchList = (projectId) => `projects/${projectId}/punch_lists`;
const viewPunchLists = (projectId) => `projects/${projectId}/punch_lists`;
const viewPunchList = (projectId, punchListId) =>
  `projects/${projectId}/punch_lists/${punchListId}`;
const getPunchListLog = (projectId, punchListId) =>
  `projects/${projectId}/punch_lists/${punchListId}/punch_list_logs`;
const viewPunchListLog = (projectId, punchListId, id) =>
  `projects/${projectId}/punch_lists/${punchListId}/punch_list_logs/${id}`;
const getFilters = (projectId) => `projects/${projectId}/punch_lists/filters`;
const addResponse = (projectId, id) =>
  `projects/${projectId}/punch_lists/${id}/punch_list_responses`;
const changePunchListStatus = (projectId, id) =>
  `projects/${projectId}/punch_lists/${id}/update_status`;
const createSubTask = (projectId) => `projects/${projectId}/sub_tasks`;
const updateSubTask = (projectId, id) => `projects/${projectId}/sub_tasks/${id}`;
const markAsClosed = (projectId, id) => `projects/${projectId}/punch_lists/${id}/mark_as_closed`;
const createCheckList = (projectId, id) =>
  `projects/${projectId}/punch_lists/${id}/check_list_items`;
const updateCheckList = (projectId, id, checklistId) =>
  `projects/${projectId}/punch_lists/${id}/check_list_items/${checklistId}`;
const getSubTask = (projectId, id) => `projects/${projectId}/sub_tasks/${id}`;

export default {
  getPunchList,
  punchListFilters,
  createPunchList,
  viewPunchLists,
  getFilters,
  addResponse,
  viewPunchList,
  changePunchListStatus,
  createSubTask,
  updateSubTask,
  markAsClosed,
  getPunchListLog,
  viewPunchListLog,
  createCheckList,
  updateCheckList,
  getSubTask
};
