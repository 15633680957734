import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SERVICE } from '../CommonApi';

export const useGetProjectLocationData = (projectId) => {
  return useQuery({
    queryKey: [`project_location`, projectId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects/${projectId}/project_locations`);
      return data;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetProjectLocationQRCodeData = (locationId, qrId) => {
  return useQuery({
    queryKey: [`project_location_qr_code`, locationId, qrId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`project_locations/${locationId}/qr_codes/${qrId}`);
      return data;
    },
    enabled: !!locationId && !!qrId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyProjectLocation = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create', id }) => {
      if (action === 'update') {
        return API_SERVICE.put(`projects/${projectId}/project_locations/${id}`, payload, true);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`projects/${projectId}/project_locations/${payload.id}`);
      }
      return API_SERVICE.post(`projects/${projectId}/project_locations`, payload, true);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ['project_location', projectId]
      });
    }
  });
};
