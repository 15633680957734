import moment from 'moment';

export default {
  required: [
    {
      required: true,
      message: ''
    }
  ],
  startTime: [
    {
      required: true,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const endTime = formInstance.getFieldValue('end_time');
        if (!value || !endTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === endTime.format('h:mm A');
        if (value.isSameOrAfter(endTime) || isSame) {
          return Promise.reject(new Error('Start time Must not be same or greater than end time'));
        }
        return Promise.resolve();
      }
    })
  ],
  endTime: [
    {
      required: true,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        if (!value || !startTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === startTime.format('h:mm A');
        if (value.isSameOrBefore(startTime) || isSame) {
          return Promise.reject(new Error('End time Must not be same or lessthan start time'));
        }
        return Promise.resolve();
      }
    })
  ],
  start: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const endTime = formInstance.getFieldValue('end_time');
        if (!value || !endTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === endTime.format('h:mm A');
        if (value.isSameOrAfter(endTime) || isSame) {
          return Promise.reject(new Error('Start time Must not be same or greater than end time'));
        }
        return Promise.resolve();
      }
    })
  ],
  end: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        if (!value || !startTime) return Promise.resolve();
        const isSame = value.format('h:mm A') === startTime.format('h:mm A');
        if (value.isSameOrBefore(startTime) || isSame) {
          return Promise.reject(new Error('End time Must not be same or lessthan start time'));
        }
        return Promise.resolve();
      }
    })
  ],
  idleTime: [
    {
      required: false,
      message: ''
    },
    (formInstance) => ({
      validator(_, value) {
        const startTime = formInstance.getFieldValue('start_time');
        const endTime = formInstance.getFieldValue('end_time');
        if (!value) return Promise.resolve();
        const difference = moment.duration(endTime.diff(startTime, undefined, true));
        const isGreater = parseFloat(value.format('HH.mm')) >= difference.hours();
        if (isGreater) {
          return Promise.reject(new Error('Idle time must not be more than worked hours'));
        }
        return Promise.resolve();
      }
    })
  ]
};
