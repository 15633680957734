import React from 'react';
import { Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import { ViewLocationContainer } from '../pages/ProjectSettings/location';
import SingUp from '../pages/Login/SignUp';
import Website from '../pages/Website';
import Features from '../pages/Website/Features';
import Header from '../pages/Website/Components/Header';
import Footer from '../pages/Website/Components/Footer';
import { Layout } from 'antd';
import Drawings from '../pages/Website/Features/Drawings';
import RFI from '../pages/Website/Features/RFI';
import Submittals from '../pages/Website/Features/Submittals';
import DailyLogs from '../pages/Website/Features/DailyLogs';
import Punchlists from '../pages/Website/Features/PunchLists';
import Locations from '../pages/Website/Features/Locations';
import AssetTracking from '../pages/Website/Features/AssetTracking';
import TimeTracking from '../pages/Website/Features/TimeTracking';
import Actions from '../pages/Website/Features/Actions';
import Forms from '../pages/Website/Features/Forms';
import Terms from '../pages/Website/Components/FooterPages/Terms';
import Privacy from '../pages/Website/Components/FooterPages/Privacy';
import Disclaimer from '../pages/Website/Components/FooterPages/Disclaimer';

const WebsiteLayout = ({ children }) => (
  <Layout>
    <Header />
    {children}
    <Footer />
  </Layout>
)

const publicRoutes = [
  { path: '/', element: <WebsiteLayout><Website /></WebsiteLayout> },
  { path: '/features/drawings', element: <WebsiteLayout><Drawings /></WebsiteLayout> },
  { path: '/features/rfi', element: <WebsiteLayout><RFI /></WebsiteLayout> },
  { path: '/features/submittals', element: <WebsiteLayout><Submittals /></WebsiteLayout> },
  { path: '/features/daily_log', element: <WebsiteLayout><DailyLogs /></WebsiteLayout> },
  { path: '/features/punchlists', element: <WebsiteLayout><Punchlists /></WebsiteLayout> },
  { path: '/features/locations', element: <WebsiteLayout><Locations /></WebsiteLayout> },
  { path: '/features/asset_tracking', element: <WebsiteLayout><AssetTracking /></WebsiteLayout> },
  { path: '/features/time_tracking', element: <WebsiteLayout><TimeTracking /></WebsiteLayout> },
  { path: '/features/actions', element: <WebsiteLayout><Actions /></WebsiteLayout> },
  { path: '/features/forms', element: <WebsiteLayout><Forms /></WebsiteLayout> },
  { path: '/features', element: <WebsiteLayout><Features /></WebsiteLayout> },
  { path: '/terms', element: <WebsiteLayout><Terms /></WebsiteLayout> },
  { path: '/privacy', element: <WebsiteLayout><Privacy /></WebsiteLayout> },
  { path: '/disclaimer', element: <WebsiteLayout><Disclaimer /></WebsiteLayout> },
  { path: '/old_home', element: <Home /> },
  { path: '/login', element: <Login /> },
  { path: '/project_locations/:locationId/qr_codes/:qrId', element: <ViewLocationContainer /> },
  { path: '/signup', element: <SingUp /> },
  { path: '/accept_invitation', element: <SingUp /> },
  { path: '/companies/:companyId/users/:id/accept_invitation', element: <SingUp /> },
  {
    path: '/companies/:companyId/contractor_companies/:id/accept_invitation',
    element: <SingUp />
  },
  { path: '/companies/:companyId/contractor_users/:id/accept_invitation', element: <SingUp /> },
  { path: '/projects/:projectId/project_users/:id/accept_invitation', element: <SingUp /> },
  { path: '/projects/:projectId/project_companies/:id/accept_invitation', element: <SingUp /> },
  { path: '*', element: <Navigate to="/" /> }
];

export default publicRoutes;
