import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { API_SERVICE } from '../CommonApi';

export const useGetCompanyCustomField = (id) => {
  return useQuery({
    queryKey: ['comp-cf', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/project_custom_fields`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCompanyCustomField = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(`companies/${id}/project_custom_fields/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/project_custom_fields/${payload.id}`);
      }
      return API_SERVICE.post(`companies/${id}/project_custom_fields`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        message.success('Updated successfully'),
        queryClient.invalidateQueries({ queryKey: ['comp-cf', id] })
      ]);
    }
  });
};

export const useGetDrawingDicipline = (id) => {
  return useQuery({
    queryKey: ['comp-dd', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/drawing_disciplines`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetActionTypes = (id) => {
  return useQuery({
    queryKey: ['action-type', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/action_types`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyActionType = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(`companies/${id}/action_types/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/action_types/${payload.id}`);
      }
      return API_SERVICE.post(`companies/${id}/action_types`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        message.success('Updated successfully'),
        queryClient.invalidateQueries({ queryKey: ['action-type', id] })
      ]);
    }
  });
};

export const useModifyDrawingDicipline = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(`companies/${id}/drawing_disciplines/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/drawing_disciplines/${payload.id}`);
      }
      return API_SERVICE.post(`companies/${id}/drawing_disciplines`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        message.success('Updated successfully'),
        queryClient.invalidateQueries({ queryKey: ['comp-dd', id] })
      ]);
    }
  });
};

export const useGetPunchListCategories = (id) => {
  return useQuery({
    queryKey: ['comp-plc', id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/punch_list_categories`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyPunchListCategories = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(`companies/${id}/punch_list_categories/${payload.id}`, payload);
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`companies/${id}/punch_list_categories/${payload.id}`);
      }
      return API_SERVICE.post(`companies/${id}/punch_list_categories`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        message.success('Updated successfully'),
        queryClient.invalidateQueries({ queryKey: ['comp-plc', id] })
      ]);
    }
  });
};

export const useModifyPunchListType = (id, companyId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload, action = 'create' }) => {
      if (action === 'update') {
        return API_SERVICE.put(
          `punch_list_categories/${id}/punch_list_types/${payload.id}`,
          payload
        );
      }
      if (action === 'delete') {
        return API_SERVICE.delete(`punch_list_categories/${id}/punch_list_types/${payload.id}`);
      }
      return API_SERVICE.post(`punch_list_categories/${id}/punch_list_types`, payload);
    },
    onSuccess: () => {
      return Promise.all([
        message.success('Updated successfully'),
        queryClient.invalidateQueries({ queryKey: ['comp-plc', companyId] })
      ]);
    }
  });
};

// eslint-disable-next-line default-param-last
export const useGetCompanyList = (page, filters, id) => {
  return useQuery({
    queryKey: ['companies', id, page, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/contractor_companies`, {
        page,
        per_page: 5,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

// eslint-disable-next-line default-param-last
export const useGetCompanyUsersList = (page, filters, id) => {
  return useQuery({
    queryKey: ['company_users', id, page, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/contractor_users`, {
        page,
        per_page: 5,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

// eslint-disable-next-line default-param-last
export const useGetContractorCompanyUsersListForProject = (page, filters, id, directoryId) => {
  return useQuery({
    queryKey: ['contractor_company_users', id, directoryId, page, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${id}/contractor_companies/${directoryId}/list_users`,
        {
          page,
          per_page: 5,
          filters
        }
      );
      return data;
    },
    enabled: !!id && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useUpdateCompanyStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.put(
        `companies/${companyId}/contractor_companies/${id}/update_status`,
        payload,
        false
      );
    }
  });
};

export const useUpdateCompanyUserStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.put(
        `companies/${companyId}/contractor_users/${id}/update_status`,
        payload,
        false
      );
    }
  });
};

export const useDeleteCompany = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/contractor_companies/${payload.id}`);
    }
  });
};

export const useDeleteCompanyUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/contractor_users/${payload.id}`);
    }
  });
};

export const useAddCompany = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/add_contractor`, payload);
    }
  });
};

export const useAddUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/add_user`, payload);
    }
  });
};

export const useGetCompanyDropdownData = (id) => {
  return useQuery({
    queryKey: [`company_base_data`, id],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/directory/options_data`);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompaniesList = () => {
  return useQuery({
    queryKey: [`companies_list`],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies`);
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyProjectsList = (companyId) => {
  return useQuery({
    queryKey: [`company_project_list`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`projects?company_id=${companyId}`);
      return data;
    },
    enabled: !!companyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
export const useGetCompanyProjectsDropDown = (companyId, payload = {}) => {
  return useQuery({
    queryKey: [`company_project_list_dropdown`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/list_projects`, payload);
      return data;
    },
    enabled: !!companyId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyData = (companyId, directoryId) => {
  return useQuery({
    queryKey: [`company_data`, companyId, directoryId],
    queryFn: async () => {
      const data = await API_SERVICE.get(
        `companies/${companyId}/contractor_companies/${directoryId}`
      );
      return data;
    },
    enabled: !!companyId && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCompanyUserData = (companyId, directoryId) => {
  return useQuery({
    queryKey: [`company_user_data`, companyId, directoryId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/contractor_users/${directoryId}`);
      return data;
    },
    enabled: !!companyId && !!directoryId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCompany = (companyId, directoryId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_companies/${directoryId}`,
        payload,
        true
      );
    }
  });
};

export const useModifyUser = (companyId, directoryId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(
        `companies/${companyId}/contractor_users/${directoryId}`,
        payload,
        true
      );
    }
  });
};

export const useGetCurrentCompanyData = (companyId) => {
  return useQuery({
    queryKey: [`current_company_data`, companyId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}`);
      return data;
    },
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetCurrentCompanyUserData = (companyId, userId) => {
  return useQuery({
    queryKey: [`current_company_user_data`, companyId, userId],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${companyId}/users/${userId}`);
      return data;
    },
    enabled: !!companyId && !!userId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useModifyCurrentCompanyUser = (companyId, userId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.put(`companies/${companyId}/users/${userId}`, payload, true);
    }
  });
};

// eslint-disable-next-line default-param-last
export const useGetCurrentCompanyUsersList = (page, filters, id) => {
  return useQuery({
    queryKey: ['current_company_users', id, page, filters],
    queryFn: async () => {
      const data = await API_SERVICE.get(`companies/${id}/users`, {
        page,
        per_page: 5,
        filters
      });
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true
  });
};

export const useModifyCurrentCompany = (companyId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.putRequest(`companies/${companyId}`, payload, true);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['current_company_data', companyId] });
    }
  });
};

export const useDeleteCurrentCompanyUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.delete(`companies/${companyId}/users/${payload.id}`);
    }
  });
};

export const useUpdateCurrentCompanyUserStatus = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.put(`companies/${companyId}/users/${id}/update_status`, payload, false);
    }
  });
};

export const useUpdateCurrentCompanyUserRole = (companyId) => {
  return useMutation({
    mutationFn: ({ payload, id }) => {
      return API_SERVICE.put(`companies/${companyId}/users/${id}/update_role`, payload, false);
    }
  });
};

export const useCurrentCompanyAddUser = (companyId) => {
  return useMutation({
    mutationFn: ({ payload }) => {
      return API_SERVICE.postRequest(`companies/${companyId}/users`, payload);
    }
  });
};
