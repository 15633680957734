import React from "react";
import {
    faTruckMoving,
    faFileLines,
    faAddressCard,
    faPersonRunning,
    faPenToSquare,
    faFileCircleCheck,
    faThumbTack,
    faLocationDot,
    faClock
} from "@fortawesome/free-solid-svg-icons";

import FeatureListItem from "./CommonComponents/FeatureListItem";

import "../Components/styles.scss";
import "./styles.scss";
import DemoSection from "../Components/DemoSection";
import ParallaxSection from "../Components/ParallaxSection";

const FeaturesHomePage = () => {
    return (
        <div className="features-homepage-wrapper">
            <div className="fh-container">
                <h1 className="hero-text">
                    <span className="blue-highlight">Empower</span> your Construction Management with ProjectLoop.
                </h1>
                <p className="sub-text">
                    ProjectLoop transforms extensive hours of construction tasks into mere seconds. Here's a compilation of the features that contribute to ProjectLoop's exceptional performance.
                </p>
            </div>
            <div className="feature-list-container">
                <FeatureListItem
                    featureURL="/features/drawings"
                    featureIcon={faPenToSquare}
                    headerText="Drawings"
                    subText="Seize command of your assets through ProjectLoop's all-encompassing management tools, streamlining asset tracking, maintenance, and compliance within your safety eco-system. Effortlessly manage your assets to optimize your safety protocols."
                />
                <FeatureListItem
                    featureURL="/features/rfi"
                    featureIcon={faFileLines}
                    headerText="RFIs"
                    subText="Streamline your safety management system effortlessly with ProjectLoop's user-friendly tools for creating and customizing forms. Craft forms to suit your specific requirements, simplifying your safety processes and enhancing compliance."
                />
                <FeatureListItem
                    featureURL="/features/submittals"
                    featureIcon={faFileCircleCheck}
                    headerText="Submittals"
                    subText="ProjectLoop simplifies the scheduling of forms for data collection and compliance monitoring, offering tailored scheduling options that seamlessly integrate with your workflow, providing ease and flexibility in your safety management processes."
                />
                <FeatureListItem
                    featureURL="/features/daily_log"
                    featureIcon={faAddressCard}
                    headerText="Daily Log"
                    subText="ProjectLoop streamlines subcontractor management, providing you with an efficient solution to maintain an extensive directory of your partners. This simplification enhances your ability to oversee and collaborate with subcontractors effortlessly."
                />
                <FeatureListItem
                    featureURL="/features/punchlists"
                    featureIcon={faThumbTack}
                    headerText="Punchlists"
                    subText="With ProjectLoop's user-friendly risk assessment tools, identifying and managing risks becomes a seamless process. Empower your team to take proactive measures in addressing safety concerns, ensuring a safer work environment."
                />
                <FeatureListItem
                    featureURL="/features/locations"
                    featureIcon={faLocationDot}
                    headerText="Locations"
                    subText="ProjectLoop grants you the capability to rapidly generate and tailor emergency plans, guaranteeing your organization's readiness for unforeseen circumstances. With our tools, you're equipped for any unexpected situation that may arise."
                />
                <FeatureListItem
                    featureURL="/features/asset_tracking"
                    featureIcon={faTruckMoving}
                    headerText="Asset Tracking"
                    subText="Simplify training certificate tracking and compliance management through ProjectLoop. This ensures your workforce remains certified and up-to-date, effectively boosting the performance of your construction management system."
                />
                <FeatureListItem
                    featureURL="/features/time_tracking"
                    featureIcon={faClock}
                    headerText="Time Tracking"
                    subText="With ProjectLoop, effortlessly streamline the organization, storage, and management of all your company documents. Enhance efficiency in your safety management system by simplifying document access, version control, and compliance."
                />
                <FeatureListItem
                    featureURL="/features/actions"
                    featureIcon={faPersonRunning}
                    headerText="Actions"
                    subText="ProjectLoop offers user-friendly tools to streamline safety action tracking and execution, enabling swift control of your construction management system, making it a seamless and efficient process."
                />
                <FeatureListItem
                    featureURL="/features/forms"
                    featureIcon={faFileLines}
                    headerText="Forms"
                    subText="Simplify user management for both individual users and companies within a unified platform. ProjectLoop's robust user management tools empower you to take control of your construction management system, streamlining access and control across your entire safety ecosystem."
                />
            </div>
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <DemoSection mergeTop={false} />
            </div>
        </div>
    )
}

export default FeaturesHomePage;
